import React from "react";
import { graphql, useStaticQuery, navigate } from 'gatsby'
import {Link} from "../../plugins/gatsby-plugin-atollon";
import {Logo, Linkedin, Instagram} from "./icons";
import FormComponent from './form'

const Footer = () => {

  const staticData = useStaticQuery(graphql`
    query DesktopFooterData {
      newsletterForm: datoCmsForm(title: {eq: "Subscribe"}) {
        ...FormProps
      }
    }
  `)

  return (
    <footer className='footer'>
      <div className='footer__top'>
        <div className='footer__inner'>
          <div className='footer__nav'>
            <div>
              <ul>
                <li><Link to='/for-sale/'>Residential</Link></li>
                <li><Link to='/commercial-for-sale/'>Commercial</Link></li>
                <li><Link to='/team/'>Team</Link></li>
                <li><Link to='/contact/'>Contact</Link></li>
              </ul>
              <div className='footer__social'>
                <Link to='https://www.linkedin.com/company/thenorthagency/' target='_blank' rel='noopener noreferrer'><Linkedin/></Link>
                <Link to='https://www.instagram.com/the.north.agency/' target='_blank' rel='noopener noreferrer'><Instagram/></Link>
              </div>
            </div>
            <div className='footer__offices'>
              <div className='footer__office'>
                <h4>Dee Why</h4>
                <p>Shop 1 / 29 Howard Avenue<br/>
                  Dee Why NSW 2099<br/>
                  <Link to='tel:+61291703683'>02 9170 3683</Link></p>
              </div>
              <div className='footer__office'>
                <h4>Mona Vale</h4>
                <p>Suite 17, Level 3<br/>
                  Gateway Building<br/>
                  1 Mona Vale Road<br/>
                  Mona Vale NSW 2103<br/>
                  <Link to='tel:+61299974444'>02 9997 4444</Link></p>
              </div>
            </div>
          </div>
          <div className='footer__subscribe'>
            <h4>Newsletter</h4>
            <p>Sign up for our newsletter and get content and property updates direct to your inbox.</p>
            <FormComponent form={ staticData.newsletterForm } />
          </div>
        </div>
      </div>
      <div className='footer__logo'>
        <div className='footer__inner'>
          <Link to='/'>
            <Logo />
          </Link>
        </div>
        <div className='footer__bottom'>
          <div className='footer__inner'>
            <ul>
              <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
              <li><Link to='/disclaimer/'>Disclaimer</Link></li>
              <li>© { new Date().getFullYear() } The North Agency</li>
              <li><Link target='_blank' rel='noopener noreferrer' to='https://atollon.com.au?utm_source=NorthAgency&utm_medium=website&utm_campaign=referrer&utm_term=realestate'>Design by Atollon</Link></li>
            </ul>
          </div>
        </div>
      </div>
      
    </footer>
  )
}

export default Footer